const initialState = {
  userLastname: '',
  userEmail: '',
  userPicture: '',
  isLogIn: false,
  userRole: ''
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_USER_NAME": {
      const { userName } = action;
      return {
        ...state,
        userName
      };
    }
    case "CHANGE_USER_LASTNAME": {
      const { userLastname } = action;
      return {
        ...state,
        userLastname
      };
    }
    case "CHANGE_USER_EMAIL": {
      const { userEmail } = action;
      return {
        ...state,
        userEmail,
      };
    }
    case "CHANGE_USER_PICTURE": {
      const { userPicture } = action;
      return {
        ...state,
        userPicture,
      };
    }
    case "CHANGE_USER_UID": {
      const { userUID } = action;
      return {
        ...state,
        userUID,
      };
    }
    case "CHANGE_LOG_IN": {
      const { isLogIn } = action.payload;
      return {
        ...state,
        isLogIn,
      };
    }
    case "CHANGE_NEW_USER": {
      const { isNewUser } = action.payload;
      return {
        ...state,
        isNewUser,
      };
    }
    case "CHANGE_USER_ROLE": {
      const { userRole } = action;
      return {
        ...state,
        userRole,
      };
    }
    default:
      return state
  }
}

export default userReducer
