import { combineReducers } from "redux"
import user from "./user/index"
import steps from "./steps/index"
import userAppointment from "./appointment/index"
import dayTrip from "./dayTrip/index"
import modales from "./modals/index"
import stats from "./stats/index"
import cookies from "./cookies/index"

const rootReducer = combineReducers({
  user: user,
  steps: steps,
  appointment: userAppointment,
  dayTrip: dayTrip,
  modales: modales,
  stats: stats,
  cookies: cookies
})

export default rootReducer
