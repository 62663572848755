const initialState = {
  dayTrips: [],
  isAnyTrips: false
}

const dayTrip = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DAY_TRIPS": {
      const {
        dayTrips
      } = action;

      return {
        dayTrips
      };
    }
    case "SET_EXIST_TRIPS": {
      const {isAnyTrips} = action;
      console.log(isAnyTrips)
      return {isAnyTrips};
    }
    default:
      return state
  }
}

export default dayTrip
