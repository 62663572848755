const initialState = {
  isFoliosOpen: false
}

const modales = (state = initialState, action) => {
  console.log(action)
  console.log(action.type)
  switch (action.type) {
    case "CHANGE_FOLIOS_MODAL": {
      const {isFoliosOpen} = action;

      return {
        ...initialState,
        isFoliosOpen
      };
    }
    default:
      return state
  }
}

export default modales
