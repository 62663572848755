const initialState = {
  step: 1,
}

const userSteps = (state = initialState, action) => {
  switch (action.type) {
    case "SET_APPOINTMENT_STEP": {
      const { step } = action;
      return {
        ...state,
        step
      };
    }
    default:
      return state
  }
}

export default userSteps
