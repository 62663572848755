const initialState = {
    activeTrips: [],
    inactiveTrips: [],
    heldTrips: [],
    subscribers: [],
    lastActiveView: 0,
    lastInactiveView: 0,
    lastHeldView: 0,
    lastSubscribersView: 0
}
const reduxDate = new Date();
console.log(reduxDate.getDay(), reduxDate.getFullYear(), reduxDate.getDate())
const dayTrip = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ACTIVE_TRIPS": {
            const {activeTrips} = action;
            return {
                ...state, activeTrips, lastActiveView: reduxDate.getDate()
            };
        }
        case "SET_INACTIVE_TRIPS": {
            const {inactiveTrips} = action;
            return {
                ...state, inactiveTrips, lastInactiveView: reduxDate.getDate()
            };
        }
        case "SET_HELD_TRIPS": {
            const {heldTrips} = action;
            return {
                ...state, heldTrips, lastHeldView: reduxDate.getDate()
            };
        }
        case "SET_SUBSCRIBERS": {
            const {subscribers} = action;
            return {
                ...state, subscribers, lastSubscribersView: reduxDate.getDate()
            };
        }
        default:
            return state
    }
}

export default dayTrip
