import { createStore, applyMiddleware } from "redux"
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import rootReducer from "../reducers/rootReducer"
import storage from 'redux-persist/lib/storage';

const middlewares = [thunk, createDebounce()];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger());
}

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(...middlewares))
)

const persistor = persistStore(store);

export {
  store,
  persistor
}
