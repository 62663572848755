const initialState = {
  name: '',
  numberOfPatients: 1,
  date: '',
  year: '',
  month: '',
  day: '',
  time: '',
  address: '',
  reference: '',
  phoneNumber: '',
  email: '',
  plan: 1,
}

const userAppointment = (state = initialState, action) => {
  switch (action.type) {
    case "SET_APPOINTMENT_USER_DATA": {
      const {
        name,
        email,
        numberOfPatients,
        phoneNumber,
        address,
        reference,
      } = action;

      return {
        ...state,
        name,
        email,
        numberOfPatients,
        phoneNumber,
        address,
        reference,
      };
    }
    case "SET_APPOINTMENT_USER_PLAN": {
      const {
        plan
      } = action;

      return {
        ...state,
        plan
      };
    }
    case "SET_APPOINTMENT_USER_CALENDAR": {
      const {
        date, year, month, day
      } = action;

      return {
        ...state,
        date, year, month, day
      };
    }
    case "SET_APPOINTMENT_USER_TIME": {
      const {
        time
      } = action;

      return {
        ...state,
        time
      };
    }
    case "SET_APPOINTMENT_USER_CONFIRM": {
      return {
        name: '',
        numberOfPatients: 1,
        date: '',
        year: '',
        month: '',
        day: '',
        time: '',
        address: '',
        reference: '',
        phoneNumber: '',
        email: '',
        plan: 1,
      };
    }
    default:
      return state
  }
}

export default userAppointment
