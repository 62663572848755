const initialState = {
    isOpen: true
}

const dayTrip = (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_COOKIES_MODAL": {
            const {isCookiesOpen} = action;
            return {isCookiesOpen};
        }
        default:
            return state
    }
}

export default dayTrip
