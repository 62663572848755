import React from 'react';

//Special
const PageComingSoon = React.lazy(() => import('./pages/Pages/Special/PageComingSoon'));
const PageComingSoon2 = React.lazy(() => import('./pages/Pages/Special/PageComingSoon2'));
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));
const PageMaintenance = React.lazy(() => import('./pages/Pages/Special/PageMaintenance'));

// Import all components
const Shop = React.lazy(() => import('./pages/Shop/index'));

// Jornadas
const Jornadas = React.lazy(() => import('./pages/Pages/Jornadas/Jornadas'));
const Jornada = React.lazy(() => import('./pages/Pages/Jornadas/Jornada'));

//Docs
const Components = React.lazy(() => import('./pages/Docs/Components'));
const Widget = React.lazy(() => import('./pages/Docs/widget'));

const PageAboutUs = React.lazy(() => import('./pages/Pages/PageAboutUs'));
const PagePricing = React.lazy(() => import('./pages/Pages/PagePricing'));
const PageServices = React.lazy(() => import('./pages/Pages/PageServices'));
const PageTeam = React.lazy(() => import('./pages/Pages/PageTeam'));

//Utility
const PagePrivacy = React.lazy(() => import('./pages/Pages/Utility/PagePrivacy'));
const PageTerms = React.lazy(() => import('./pages/Pages/Utility/PageTerms'));

//Contact
const PageContactOne = React.lazy(() => import('./pages/Pages/Contact/PageContactOne'));

//Email
const EmailAlert = React.lazy(() => import('./pages/Pages/EmailTemplate/EmailAlert'));
const EmailPasswordReset = React.lazy(() => import('./pages/Pages/EmailTemplate/EmailPasswordReset'));
const EmailConfirmation = React.lazy(() => import('./pages/Pages/EmailTemplate/EmailConfirmation'));
const EmailInvoice = React.lazy(() => import('./pages/Pages/EmailTemplate/EmailInvoice'));

//Help Center
const HelpCenterOverview = React.lazy(() => import('./pages/Pages/HelpCenter/HelpCenterOverview'));
const HelpCenterFaqs = React.lazy(() => import('./pages/Pages/HelpCenter/HelpCenterFaqs'));
const HelpCenterGuides = React.lazy(() => import('./pages/Pages/HelpCenter/HelpCenterGuides'));
const HelpCenterSupportRequest = React.lazy(() => import('./pages/Pages/HelpCenter/HelpCenterSupportRequest'));


const routes = [
    //routes without Layout

    //Email Pages
    {path: '/email-alert', component: EmailAlert, isWithoutLayout: true},
    {path: '/email-password-reset', component: EmailPasswordReset, isWithoutLayout: true},
    {path: '/email-confirmation', component: EmailConfirmation, isWithoutLayout: true},
    {path: '/email-invoice', component: EmailInvoice, isWithoutLayout: true},

    //Special Pages
    {path: '/page-comingsoon', component: PageComingSoon, isWithoutLayout: true},
    {path: '/page-comingsoon2', component: PageComingSoon2, isWithoutLayout: true},
    {path: '/page-error', component: PageError, isWithoutLayout: true},
    {path: '/page-maintenance', component: PageMaintenance, isWithoutLayout: true},

    // Landings
    {path: '/index-shop', component: Shop},

    {path: '/page-aboutus', component: PageAboutUs},
    {path: '/page-pricing', component: PagePricing},
    {path: '/page-services', component: PageServices},
    {path: '/page-team', component: PageTeam},

    //Help Center
    {path: '/helpcenter-overview', component: HelpCenterOverview},
    {path: '/helpcenter-faqs', component: HelpCenterFaqs},
    {path: '/helpcenter-guides', component: HelpCenterGuides},
    {path: '/helpcenter-support-request', component: HelpCenterSupportRequest},

    //Utility
    {path: '/page-terms', component: PageTerms},
    {path: '/page-privacy', component: PagePrivacy},

    //Page Contact
    {path: '/page-contact-one', component: PageContactOne},

    //Docs
    {path: '/components', component: Components},
    {path: '/widget', component: Widget},

    // Jornadas
    {path: '/jornadas', component: Jornadas, isWithoutLayout: true},
    {path: '/jornada/:id', component: Jornada, isWithoutLayout: true},

    //Index Main
    {path: '/index', component: Shop, isWithoutLayout: true},

    //Index root

    {path: '/', component: Shop, isWithoutLayout: true, exact: true},
    {component: PageError, isWithoutLayout: true, exact: false},

];

export default routes;
